body {
    // background: #0e2132 !important;
}
.image {
    height: 130px;
}

.loginHeading {
    // text-transform: uppercase;
    font-weight: 600;
    font-size: 28px;
    width: 100%;
    color: white !important;
}

.loginsection {
    min-width: 450px;
    width: 450px;
}

.logincontainer {
    padding: 10px;
    //height: calc(100vh - 165px);
    // width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    background: #0e2132 !important;
}

.MuiOutlinedInput-root {
    position: relative;
    border-radius: 4px;
    height: 58px !important;
    background: white;
    border: none;
}
.SignButton {
    background: rgb(86, 145, 1) !important;
    width: 40%;
    font-size: 15px !important;
    padding: 15px !important;
    text-transform: uppercase;
    font-weight: 550 !important;
    // height: 50px;
    margin-top: 30px !important;
    color: white !important;
}

::placeholder {
    font-family: Montserrat !important;
    font-weight: 600;
    color: blue !important;
    text-align: center;
}

.MuiIconButton-edgeEnd {
    // margin-right: -12px;
    display: none !important;
}

@media (-webkit-device-pixel-ratio: 1.25) {
    .logincontainer {
        //height: calc(100vh - -6px);
        // width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .image {
        height: 80px !important;
    }
    .loginHeading {
        font-size: 20px !important;
    }

    .loginsection {
        min-width: 350px;
        width: 350px;
    }
}
