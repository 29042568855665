.bold {
    font-weight: bold !important; 
    border-bottom: 1px solid #ddd;
}

.MuiMenuItem-root {
    font-size: 13px !important;
}

.tableUsedStockReport {
    display: block;
    overflow: auto; 
    white-space: nowrap; 
    width: 100%;
    max-height: 700px; 
    height: 100%;

    .MuiTableCell-root {
        font-size: 12px !important;
    }

    .MuiTableCell-body {
        white-space: break-spaces;
    }

    .MuiTableRow-head {
        .MuiTableCell-root {
            font-weight: 500;
            border-bottom: none;
            border-right: 1px solid #00000026;

            &:last-child {
                border-right: none;
            }
        }

        .headerCustomFinal {
            background: #D9D9D9;
            position: sticky;
            top: 0;
            z-index: 1100;

            &.hidden {
                display: none;
            }
        }

        .stickyHeaderCustom {
            background: #A59F9F;
            position: sticky;
            top: 0;
            z-index: 1199;

            &:after {
                content: "";
                border-right: 2px solid rgba(0, 0, 0, 0.42);
                position: absolute;
                top: 0;
                right: 0;
                width: 2px;
                height: 100%;
            }
    
            .MuiTableRow-head {
                .MuiTableCell-root {
                    color: #fff;
                    font-weight: 500;
                    border-bottom: none;
                    border-right: 1px solid #ffffff54;

                    &:last-child {
                        border-right: none;
                    }
                }
            }
    
            @media (min-width: 1199px) {
                left: 0;
            }
        }
    }

    


    .MuiTableCell-root {
        font-family: Montserrat;
        color: #000;
        letter-spacing: normal;
        font-weight: 400;
        line-height: 1;
        padding: 12px 10px;
        border-bottom: none;
        display: inline-block;
        min-height: 11px;
    }

    .bodyTableCustom {
        background-color: #fff;
    }

    .stickyColumnsCustom {
        position: relative;
             
        &:after {
            content: "";
            border-right: 2px solid rgba(0, 0, 0, 0.42);
            position: absolute;
            top: -1px;
            right: 0;
            width: 2px;
            height: 105%;
        }

        @media (min-width: 1199px) {
            position: sticky;
            left: 0;
            z-index: 1100;
        }
    }

    .rowTableCustom {
        background: #FFF;
        border-bottom: 1px solid #C8C8C8;
        transition: 0.2s all;

        .stickyColumnsCustom {
            background: #FFF;
        }

        &:nth-child(odd) {
            background: #F9F8F8;

            .stickyColumnsCustom {
                background: #F9F8F8;
            }
        }

        &.active {
            background: #9ebb71;

            .stickyColumnsCustom {
                background: #9ebb71;
            }
        }

        .redValue {
            color: rgb(255, 48, 17);
        }

        .greenValue {
            color: rgb(98, 173, 84);
        }

        .reservedBox {
            color: #0C2032;
            text-align: center;
            font-size: 10px;
            font-weight: 700;
            text-transform: capitalize;
            padding: 2px 5px;
            border-radius: 2px;
            background: #A9E499;
        }

        .reservedToolTipWrp {
            position: relative;

            &:hover {
                .reservedToolTip {
                    visibility: visible;
                    opacity: 1;
                }
            }
            
            .reservedToolTip {
                position: absolute;
                background: #fff;
                box-shadow: 0px 0px 3px 0px #858585;
                opacity: 0;
                visibility: hidden;
                transition: 0.2s all;
                bottom: -11px;
                padding: 5px;
                border-radius: 5px;
                width: 200px;
                
                .lineReserved {
                    display: block;
                    margin-bottom: 5px;
                    font-size: 11px;

                    span {
                        font-weight: 600;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .columnSize60 {
        width: 60px;
        display: inline-block;
    }

    .columnSize80 {
        width: 80px;
        display: inline-block;
    }

    .columnSize95 {
        width: 95px;
        display: inline-block;
    }

    .columnSize100 {
        width: 100px;
        display: inline-block;
    }

    .columnSize120 {
        width: 120px;
        display: inline-block;
    }

    .columnSize200 {
        width: 200px;
        display: inline-block;
    }

    .columnSize250 {
        width: 250px;
        display: inline-block;
    }

    .columnSize150 {
        width: 150px;
        display: inline-block;
    }

    .spanEdit {
        cursor: pointer;
        margin-left: 5px;
        transition: 0.2s all;
        width: auto;
        display: inline-block;
        
        &:hover {
            svg {
                path {
                    stroke: #6366F1;
                }
            }
        }
    }

    .hidden {
        opacity: 0 !important;
        visibility: hidden !important;
        width: 0;
    }

    .tableSelect {
        &.hidden {
            display: none;
        }
    }

    .priceIndicator {
        width: 120px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        font-weight: 600;

        &.good {
            background: rgb(30, 100, 70);
            color: #fff;
        }

        &.great {
            background: rgb(135, 193, 160);
        }

        &.high {
            background: rgb(255, 154, 122);
        }

        &.fair {
            background: rgb(251, 186, 0);
        }

        &.low {
            background: rgb(255, 48, 17);
        }

        &.noanalysis {
            background: rgb(199, 202, 206);
        }
    }

    .yellow {
        color: #DA8505;
    }

    @media (max-width: 1199px) {
        max-height: 500px;
    }
}

.customSelectFilterSingle {
    width: 100%;
    background: #fff;
    height: 58px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.17) !important;

    &::before, &::after {
        display: none;
    }

    svg {
        right: 10px;

        &:hover {
            background: #eaeaea;
            border-radius: 50%;
        }
    }

    .MuiInput-input:hover ~ svg {
        background: #eaeaea;
        border-radius: 50%;
    }
}

.stockTableHeader {
    text-align: left;

    .exportCSVButton {
        position: absolute;
        right: 0;
        top: 0;

        &.custom {
            a {
                justify-content: center;
                font-size: 12px;

                svg {
                    margin-left: 10px;
                }
            }
        }

        @media (max-width: 1199px) {
            display: none;
        }
    }

    .stockTableHeaderTabs {
        margin-bottom: 20px; 
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: #C8C8C8;
            left: 0;
            bottom: 0;
        }

        .tableSelectButtons {
            color: #A59F9F;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            text-transform: capitalize;
            margin-right: 30px;
            display: inline-flex;
            align-items: center;
            transition: 0.2s all;
            padding-bottom: 10px;
            position: relative;

            svg {
                margin-right: 10px;

                path {
                    stroke: rgb(165, 159, 159);
                    transition: 0.2s all;
                }
            }
            
            &:hover {
                color: #000;

                &::after {
                    content: "";
                    position: absolute;
                    width: 110%;
                    height: 2px;
                    background: #000;
                    left: 0;
                    bottom: 0;
                    z-index: 1;

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }

                svg {
                    path {
                        stroke: #000;
                    }
                }
            }

            &.active {
                color: #4871D9;

                &::after {
                    content: "";
                    position: absolute;
                    width: 110%;
                    height: 2px;
                    background: #4871D9;
                    left: 0;
                    bottom: 0;
                    z-index: 1;

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }

                svg {
                    path {
                        stroke: #4871D9;
                    }
                }
            }

            @media (max-width: 767px) {
                width: 40%;
                margin-top: 10px;
            }
        }
    }

    .stockTableHeaderTitle {
        font-size: 21px;
        font-weight: 500;
        display: block;
        margin-bottom: 25px;

        @media (max-width: 1199px) {
            margin-bottom: 20px;
        }
    }

    .stockTableHeaderFilters {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .filterTopPage {
            margin-bottom: 0 !important;
            border-radius: 8px;

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        .textFielWithIcon {
            position: relative;
            margin-right: 10px;

            svg {
                position: absolute;
                z-index: 1;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
            }

            label {
                transform: translate(50px, 22px) scale(1);

                span {
                    display: none;
                }
            }

            .MuiOutlinedInput-inputMarginDense {
                padding-left: 35px;
            }

            .MuiInputLabel-shrink {
                transform: translate(14px, -6px) scale(0.75);
            }

            @media (max-width: 1199px) {
                width: 50%;
            }

            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
            }
        }

        .customTextBox {
            border: none;
            margin: 0;
            border-radius: 8px;

            .MuiFormLabel-root.Mui-focused {
                color: rgba(47, 45, 45, 0.75) !important;
            }

            fieldset {
                border: 1px solid rgba(0, 0, 0, 0.17) !important;
            }
        }

        @media (max-width: 767px) {
            display: block;
        }
    }
}