.screen {
    // margin: 10px 20px;

    .add_btn {
        position: absolute;
        top: 107px;
        right: 28px;
        border-radius: 2rem;
    }
}

.submit_btn {
    margin-bottom: 17px !important;
}

.mandatory-fields {
    .MuiInputLabel-animated {
        color: red !important;
    }
    .MuiFormHelperText-root {
        color: red;
    }
}
.gridadd-btn {
    overflow: hidden;
    .MuiButton-root {
        top: -1px !important;
        font-size: 0.7rem;
        text-transform: none;
        padding-top: 10.7px;
        display: inline-flex !important;
        padding-bottom: 10.7px;
    }
    .MuiButton-contained {
        box-shadow: none;
    }
}

.input-Number-remove-spinners {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    & input[type='number'] {
        -moz-appearance: textfield;
    }
}

.branchLimitsWrp {
    .branchLimitsInr {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        
        .branchLimitsLine {
            margin-bottom: 30px;
            width: 30%;

            span {
                display: block;
                margin-bottom: 5px;
            }
    
            .customInput {
                border: 1px solid #ddd;
                max-width: 200px;
                padding: 0 10px;
                line-height: 1;
                max-height: 30px;
            }
        }
    }
}