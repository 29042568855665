.app-select {
    &::before,
    &::after {
        content: none !important;
    }
    select {
        border: 1px solid #ced4da;
        padding: 5px 20px 7px 6px;
        position: relative;
        font-size: 15px;
        transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
            'Segoe UI Symbol';
        border-radius: 4px;
        background-color: #fff;

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
            border-color: #80bdff !important;
            border-radius: 4px !important;
        }
    }
}

.app-btn-group {
    .app-select-btn-grp {
        button {
            text-transform: unset;
            word-wrap: break-word;
            // white-space: nowrap;
            &.Mui-selected {
                color: white;
                background-color: rgb(83, 175, 80);
            }
        }
    }
}

.greenbtn {
    &.MuiButton-contained {
        background-color: green;
    }
}

.uploadWithBadgeButton {
    .MuiButton-fullWidth {
        width: 25px;
    }
    .btnWithoutCheckbox {
        height: 25px !important;
        min-width: 28px !important;
        border: 1px solid #c5bfbf !important;
        .doneIcon {
            color: #c5bfbf;
        }
    }
    .btnCheckbox {
        height: 25px !important;
        border: 1px solid #3f51b5 !important;
        min-width: 28px !important;
        .doneIcon {
            color: #3f51b5;
        }
    }
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-right: 65px;
    height: 50px;
    background-color: #fff;
    padding-right: 25px !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px;
    min-height: 29px;
    background-color: #fff;
}

@media screen and (max-width: 1024px) {
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        height: auto;
    }
}

// @media screen and (max-width: 1590px) {
//     .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
//         // padding-right: 65px;
//         min-height: 35px;
//         // background-color: #fff;
//     }
//     .MuiSelect-outlined.MuiSelect-outlined {
//         padding-right: 32px;
//         min-height: 19px;
//         background-color: #fff;
//     }
// }

.MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 8px;
}
.MuiListSubheader-gutters {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
