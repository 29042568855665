@import './../Core/FormInput/Controls.scss';

.content {
    flex-grow: 1;
    overflow: auto;
   // height: calc(100vh - 97px);
   height: 100vh;
}

@media (-webkit-device-pixel-ratio: 1.25) {
    .ScaleResizerClass {
        zoom: 0.9;
        // background-color: #f50057;
        height: 97.4%;

        .logincontainer {
            height: 100%;
        }
    }
    .content {
        flex-grow: 1;
        overflow: auto;
        //height: calc(100vh - 100px);
    }
}

.close-left-nav {
    .MuiButtonBase-root {
        padding-left: 35px;

        .MuiListItemText-root {
            opacity: 0;
        }
    }
}

.logoImgWrp {
    margin-top: 60px;
    margin-bottom: 25px;
    margin-left: 24px;
    
    img {
        display: block;
        width: 60px;
        height: 67px;
    }

    &.open {
        img {
            width: 100%;
            max-width: 214px;
        }
    }
}

.filterTopPage {
    width: 268px;
    position: relative;
    margin-left: 0px !important;
    margin-bottom: 10px !important;
    margin-right: 10px !important;

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(0, 0, 0, 0.17) !important;
    }

    .labelInput {
        font-size: 12px;
        position: absolute;
        color: rgba(47, 45, 45, 0.75);
        z-index: 99;    
        left: 10px;
        top: 10px;
    }

    .MuiFormControl-root {
        margin: 0 !important;
    }

    .MuiInputBase-root {
        padding-top: 25px !important;

        .MuiSelect-root {
            padding: 0;
            text-align: left;
            font-size: 12px;
            padding-left: 10px;
        }

        @media (max-width: 320px) {
            padding-right: 10px;
        }
    }

    .MuiSelect-select:focus {
        background-color: #fff !important;
    }

    .MuiBadge-root {
        &.custom-chip {
            .MuiChip-root {
                display: none;
            }

            .MuiBadge-badge {
                transform: none;
                border-radius: 2px;
                background-color: #D7D9D5;
                right: unset;
                top: unset;
                margin-left: 15px;
                color: #000;
                font-weight: 700;
                position: relative;
            }
        }

    }

    .MuiChip-root {
        height: 20px;
        font-size: 12px;
        background: none;

        .MuiChip-label {
            padding: 0;
            padding-left: 4px;

            @media (max-width: 767px) {
                font-size: 12px;
            }
        }

        .MuiBadge-badge {
            @media (max-width: 767px) {
                font-size: 10px;
                min-width: 15px;
                height: 15px;
            }
        }
    }

    &:nth-child(even) {
        @media (max-width: 1199px) {
            margin-right: 0 !important;
        }
    }

    @media (max-width: 1800px) {
        width: 230px;
    }

    @media (max-width: 1199px) {
        width: 49%;
    }

    @media (max-width: 767px) {
        width: 47%;

        &:first-child {
            width: 100%;
            margin-left: 0px !important;
        }
    }
}

.filterTopPageWrp {
    &.gridSpacing {
        display: grid !important;
        grid-template-columns: repeat(6, 1fr) !important;

        .filterTopPage {
            width: auto !important;

            &:last-child {
                margin-right: 0 !important;
            }

            @media (max-width: 767px) {
                margin-right: 0 !important;
            }
        }

        @media (max-width: 1199px) {
            grid-template-columns: repeat(2, 1fr) !important;
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr) !important;
        }
    }

    @media (max-width: 767px) {
        justify-content: space-between;
    }
}

.open-left-nav {
    .MuiButtonBase-root {
        padding-left: 35px;

        .MuiListItemText-root {
            opacity: 1;
        }
    }
}

.custom-scroll {
    overflow: auto;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.App {
    text-align: center;
}
.removeScroll {
    overflow: hidden !important;
}
.addbtn {
    float: left;
    margin: 8px !important;
    position: fixed;
    border-radius: 20px !important;
    top: -15px !important;
}
.btnadd {
    float: right;
    margin: 8px !important;
    position: fixed;
    border-radius: 20px !important;
    top: -15px !important;
}

@media (prefers-reduced-motion: no-preference) {
    .spin-always {
        animation: spin-animate infinite 20s linear;
    }
}

.header {
    & > .portal-info {
        background-color: #3f598d;
        height: 60px;
        display: flex;
        font-size: 15px;
        padding-left: 10px;

        .branding-logo {
            width: 125px;
            height: 50px;
            margin-right: 20px;
            @media (max-width: 767px) {
                width: 125px;
                height: 50px;
                margin-right: 0px;
            }
            img {
                // width: 100%;
                height: 66px !important;
                width: 190px !important;
                margin-left: 20px;
                margin-top: -10px;
            }
        }

        .nav-bar-icons {
            // border-left: 1px solid white;
            @media (max-width: 769px) {
                display: none !important;
            }
            @media (max-width: 400px) {
                display: none !important;
            }
        }

        > .info {
            font-size: 90%;
            display: flex;
            flex: 1 0 calc(25% - 10px);
            align-items: center;
            justify-content: center;

            .icon {
                margin-top: 5px;
                margin-right: 10px;
                @media (max-width: 767px) {
                    margin-right: 5px;
                    .MuiSvgIcon-fontSizeLarge {
                        font-size: 1.1875rem !important;
                    }
                }
            }

            .key-value {
                text-align: left;
                label:first-child {
                    text-transform: uppercase;
                }

                label.value {
                    font-weight: bold;
                    display: block;
                }
            }

            @media (max-width: 767px) {
                .key-value {
                    font-size: 12px;
                }
                label.value {
                    font-size: 10px;
                }
            }
        }
    }

    & > .navbar {
        background-color: #3f598d;
        height: 40px;
        display: flex;
        font-size: 15px;
        padding-left: 10px;
        color: white;

        ul {
            list-style-type: none;
            padding: 0px;
            margin-top: 5px;

            li {
                display: inline-block;
                margin-right: 5px;
                a {
                    padding: 5px 10px;
                    display: block;
                    text-decoration: none;
                    transition: 0.2s;
                    color: inherit;
                    &:hover {
                        transform: translateY(2px);
                    }
                }
            }
        }

        ul.pull-right {
            margin-left: auto;
            li {
                border-width: 0px;

                .last-login {
                    font-size: 80%;
                    font-style: italic;
                }
            }
        }
    }
}

.App-link {
    color: #61dafb;
}
.active-link {
    .MuiButton-label {
        color: #f50057 !important;
    }
}
.active-link-btn {
    .MuiListItem-button {
        color: #f50057 !important;
    }
}

.MuiBreadcrumbs-ol {
    display: flex;
    padding: 0;
    /* flex-wrap: wrap; */
    list-style: none;
    /* align-items: center; */
    margin: 14px !important;
    font-size: 0.85rem !important;
}
@keyframes spin-animate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.pa-4 {
    padding: 4px;
    width: 100%;
}
.px-10 {
    padding: 0px 10px;
}
.mt-11 {
    margin-top: -11px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-20 {
    margin-top: 20px !important;
}

.no-caps {
    text-transform: unset !important;
}
.rightButton {
    float: right;
    padding: 1px !important;
    margin-top: -2px !important;
    margin-bottom: -17px !important;
}
.common-gijgo-grid {
    table.gj-grid-bootstrap thead th {
        background-color: #f5f5f5 !important;
        vertical-align: middle;
    }
    .gj-grid-md th {
        vertical-align: bottom;
        font-weight: 500;
        font-size: 0.83rem !important;
        line-height: 1px;
        height: 26px !important;
        color: rgb(15, 15, 15) !important;
        border-right: 1px solid #9e9e9e !important;
    }
    .gj-grid-md {
        font-size: 0.77rem !important;
        white-space: none !important;
    }
    .gj-grid-md tbody tr {
        height: 26px !important;
    }
    .gj-grid-md td {
        padding: 0px 4px !important;
        height: 16px !important;
        font-size: 0.875rem !important;
    }
    .gj-width-full {
        width: 100%;
        height: 22px;
    }
    table.gj-grid-md thead th [data-role='sorticon'] i.gj-icon,
    table.gj-grid-md thead th [data-role='sorticon'] i.material-icons {
        top: 7px;
        padding-left: 3px;
    }
    .gj-grid-md tfoot tr[data-role='pager'] .gj-dropdown-md [role='presenter'] {
        font-size: 16px !important;
        margin-top: -13px;
    }

    .grid-filter-select-th {
        padding: 10px 6px 12px 5px !important;
        text-align: center;
    }

    TABLE.gj-grid THEAD TH {
        text-overflow: unset !important;
        padding: 12px 13px 12px 5px;
    }

    table.gj-grid tfoot DIV[data-role='display'] {
        text-indent: 13px !important;
        margin-top: 9px;
    }

    .gj-button-md.gj-button-md-icon {
        top: -14px;
    }
    .grid-filter-select {
        height: 28px;
        width: 100%;
    }
}
.black-iconmt {
    font-size: 25px;
    font-weight: bold;
    color: black;
}

.userSign {
    .signatureCanvas {
        border: 1px solid black;
        width: 100%;
        height: 140px;
        margin-top: 9px;
    }
}

.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
    padding-top: '4.1px';
    padding-bottom: '4.1px';
}

.data-grid-container {
    .MuiDataGrid-main {
        .MuiDataGrid-cell,
        .MuiDataGrid-colCell {
            padding: 0px 8px !important;
        }
    }
}

//Overwritting popup css
.MuiAutocomplete-popper {
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #569101;
    }

    .MuiPaper-root {
        .MuiGrid-root {
            background-color: #fff !important;
            box-shadow: 0px 0px 4px 2px #0000001f;
            text-align: center !important;
            position: relative;
            z-index: 1;

            .MuiButton-root {
                background-color: rgba(86, 145, 1, 0.7) !important;
                color: #fff !important;
                font-weight: 700;
                text-transform: capitalize;
            }
        }
    }

    @media (max-width: 767px) { 
        width: 100% !important;
        max-width: 345px !important;
    }
}