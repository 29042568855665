.stockRowWrp {
    margin: 30px -10px 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .stockBoxItemTitle {
        color: #537ADB;
        font-size: 16px;
        font-weight: 500;
        display: block;
        margin-bottom: 10px;
    }
    
    .stockBoxWrp {
        margin: 0 10px 20px;
        padding: 30px;
        background: #fff;
        border-radius: 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
        text-align: left;

        &.flexbox {
            display: flex;

            @media (max-width: 1199px) {
                display: inline-block;
            }
        }

        &.swiperWrp {
            max-width: 305px;
            width: 100%;

            .usedStockSwiper {
                height: 100%;

                .swiper-container {
                    height: 100%;

                    .swiper-pagination-bullet {
                        width: 15px;
                        height: 15px;
                        border: 1px solid #4871D9;
                        background-color: #fff;
                        opacity: 1;

                        &.swiper-pagination-bullet-active {
                            background-color: #4871D9;
                        }
                    }
                }
            }
        }

        .stockGraphIndicatorTop {
            margin-bottom: 15px;

            .stockGraphIndicatorTopTitle {
                color: #000;
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;
                display: block;
                margin-bottom: 5px;
            }

            .stockGraphIndicatorTopSub {
                color: #A59F9F;
                font-size: 14px;
                font-weight: 500;
            }
        }

        .stockGraphIndicatorGraph {
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .graphColWrp {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                .graphColTop {
                    text-align: center;

                    .graphColValue {
                        color: #000;
                        font-size: 14px;
                        font-weight: 500;
                        
                        span {
                            color: #A59F9F;
                        }
                    }
                }

                .graphCol {
                    width: 70px;
                    margin: 0 auto;

                    @media (min-width: 1440px) and (max-width: 1800px) {
                        width: 50px;
                    }

                    @media (max-width: 1199px) {
                        width: 40px;
                    }
                }

                .graphColBtm {
                    text-align: center;
                    margin-top: 10px;

                    .graphDesc {
                        color: #000;
                        font-size: 10px;
                        padding: 3px 4px;
                        border-radius: 5px;
                        font-weight: 600;


                        @media (max-width: 767px) {
                            padding: 2px 1px;
                        }
                    }
                }

                @media (max-width: 1199px) {
                    margin-right: 5px;
                }
            }

            @media (max-width: 991px) {
                margin-bottom: 60px;
            }

            @media (max-width: 767px) {
                max-width: 250px;
                margin: 0 auto 60px;
            }
        }

        .stockBoxRightTop {
            margin-top: 10px;
        }

        .stockBoxLeft {
            position: relative;
            padding-right: 15px;
            margin-right: 15px;
            width: 50%;
            max-width: 230px;

            &::after {
                content: "";
                position: absolute;
                background-color: rgba(0, 0, 0, 0.40);
                width: 0.5px;
                height: 90%;
                top: 50%;
                transform: translateY(-50%);
                right: 0;

                @media (max-width: 1199px) {
                    display: none;
                }
            }

            .stockBoxTop {
                display: flex;
                justify-content: space-between;

                .stockBoxTopLeft {
                    width: 100%;

                    .stockBoxLeftMainTitle {
                        color: #2F2D2D;
                        font-size: 28px;
                        font-weight: 700;
                        display: block;
                        margin-bottom: 3px;
        
                        span {
                            font-weight: normal;
                        }
                    }
        
                    .stockBoxLeftSubTitle {
                        color: #5F83DE;
                        font-size: 16px;
                        font-weight: 500;
                        display: block;
                        margin-bottom: 20px;

                        &.customSpacing {
                            margin-bottom: 5px;
                        }
                    }

                    .stockBoxLeftBudget {
                        color: #C8C8C8;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;

                        .stockBoxBudgetValue {
                            color: #000;
                        }

                        .stockBoxBudgetPercent {
                            &.greenBudget {
                                color: rgb(124, 191, 106);
                            }

                            &.redBudget {
                                color: #CE2020;
                            }
                        }
                    }

                    .stockBoxRetail {
                        display: flex;
                        justify-content: space-between;
                        color: #000;
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: 15px;
                        padding-top: 5px;
                        margin-bottom: 5px;
                        padding-bottom: 5px;
                        position: relative;
                        border-top: 1px solid rgba(200, 200, 200, 0.50);
                        border-bottom: 1px solid rgba(200, 200, 200, 0.50);
                    }

                    .stockBoxAvgSiv {
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 20px;
                    }

                    .stockBoxLeftToDo {
                        font-size: 18px;
                        font-weight: 500;
                        display: block;
                        margin-bottom: 10px;
                        
                        .stockToDoValue {
                            text-decoration: underline;
                            color: #4871D9;
                        }

                        span {
                            color: #2F2D2D;
                        }
                    }

                    .stockBoxMainLine {
                        color: #2F2D2D;
                        font-size: 28px;
                        font-weight: 700;

                        span {
                            color: #C8C8C8;
                            font-size: 16px;
                            font-weight: 700;
                        }
                    }

                    .stockBoxMainLineText {
                        color: #000;
                        font-size: 16px;
                        font-weight: 500;
                        display: block;
                        margin-top: 5px;
                    }

                    .stockBoxSmallLine {
                        font-size: 10px;
                        margin-top: -3px;
                        display: inline-block
                    }
                }

                .stockBoxTopRight {
                    .stockBoxTopRightValue {
                        color: #A59F9F;
                        text-align: right;
                        font-size: 14px;
                        font-weight: 500;
                        display: block;

                        span {
                            color: #000;
                        }
                    }
                }
            }

            .stockBoxTypesBarWrp {
                .stockBoxTypesBar {
                    width: 100%;
                    height: 32px;
                    margin: 30px 0 10px;
                    position: relative;
                    background: #A19696;
                    display: flex;
                }

                .stockBoxTypesInr {
                    display: flex;

                    .stockBoxTypes {
                        display: flex;
                        align-items: center;

                        &:nth-child(2) {
                            margin: 0 10px;
                        }

                        span {
                            color: #000;
                            font-size: 12px;
                            font-weight: 600;

                            &.stockBoxTypesColour {
                                display: inline-block;
                                width: 15px;
                                height: 15px;
                                border-radius: 50%;
                                margin-right: 3px;
                            }

                            &.stockBoxTypeValue {
                                display: inline-block;
                                margin-right: 3px;
                            }

                            @media (min-width: 1441px) and (max-width: 1700px) {
                                font-size: 10px;
                            }
                        }
                        
                    }

                    @media (min-width: 768px) and (max-width: 1199px) {
                        justify-content: space-evenly;
                    }
                }
            }

            .stockBoxDaysStock {
                margin-top: 20px;
            }

            .currentStockBars {
                .currentStockBarWrp {
                    margin-bottom: 15px;
    
                    .currentStockTitle {
                        color: #000;
                        font-size: 14px;
                        font-weight: 500;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 5px;
    
                        span {
                            span {
                                color: #A59F9F;
                            }
                        }
    
                        .currentStockValue {
                            font-weight: 700;
                        }
                    }
    
                    .currentStockBar {
                        border-radius: 4px;
                        background: #C8C8C8;
                        height: 15px;
                        width: 100%; 
                        display: block;
                        position: relative;
                        margin-bottom: 5px;

                        .currentStockPercentage {
                            border-radius: 4px;
                            background: var(--Color, #4871D9);
                            height: 15px;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                    }

                    .currentStockVariance {
                        font-size: 14px;
                        font-weight: 500;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &.redValue {
                            color: #CE2020;
                        }

                        .currentStockPercent {
                            color: #000;
                        }

                        .currentStockCheck {
                            display: flex;
                            align-items: center;

                            .currentStockLink {
                                margin-right: 3px;
                                cursor: pointer;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            @media (min-width: 1441px) and (max-width: 1700px) {
                width: 45%;
            }

            @media (max-width: 1199px) {
                margin-right: 0;
                padding-right: 0;
                width: 100%;
                max-width: 100%;
            }
        }

        .stockBoxRight {
            width: 50%;

            .stockBoxRightBottom {
                margin-top: 15px;
                border-top: 1px solid #ddd;
                padding-top: 15px;
            }

            .stockSmallText {
                font-size: 12px;
                margin-top: -10px;
                margin-bottom: 10px;
                display: block;
            }

            .stockBoxLeftSubTitle {
                color: #5F83DE;
                font-size: 16px;
                font-weight: 500;
                display: block;
                margin-bottom: 20px;

                &.backText {
                    color: #000;
                }
            }

            .overagedValue {
                color: #D43C3C;
                font-size: 24px;
                font-weight: 700;
                display: block;
                margin-bottom: 5px;
            }

            .halfCircleProgress {
                width: 100%;
                height: auto;
                text-align: center;
                position: relative;
                margin-top: -35px;

                .halfCircleText {
                    position: absolute;
                    top: 37%;
                    left: 50%;
                    transform: translate(-50%);
                    width: 145px;

                    .halfCircleValue {
                        color: rgb(47, 45, 45);
                        font-style: normal;
                        font-weight: 700;
                        font-size: 36px;
                        display: block;
                    }

                    .halfCircleSub {
                        color: #000;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    .halfCircleSmall {
                        display: block;
                        color: #000;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }

            .lineItemsWrp {
                margin-top: -30px;
            }
        }

        @media (max-width: 1440px) {
            padding: 20px 20px;
        }

        @media (max-width: 1199px) {
            margin: 0 10px 15px;
        }
    }

    .stockBoxDaysStockValueWrp {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        &.customspacing {
            margin-top: 30px;
        }

        .stockBoxDaysStockValueLeft {
            display: flex;
            width: 58%;
            align-items: center;

            .stockBoxDaysStockValueDesc {
                color: #A59F9F;
                font-size: 14px;
                font-weight: 500;
                flex: 0 0 80px;

                @media (min-width: 1441px) and (max-width: 1700px) {
                    font-size: 12px;
                }
            }
            
            .stockBoxDaysStockValueProgress {
                height: 12px;
                display: inline-block;
            }
        }
        
        .stockBoxDaysStockValueRight {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .stockBoxDaysStockValue {
                color: #000;
                text-align: right;
                font-size: 14px;
                font-weight: 500;
                display: inline-block;
                margin-right: 5px;

                .stockBoxValue {
                    color: #4871D9;
                    text-decoration: underline;
                    cursor: pointer;
                }

                span {
                    color: #A59F9F;
                }

                @media (min-width: 1441px) and (max-width: 1700px) {
                    font-size: 12px;
                }
            }

            .stockBoxDaysStockValueLink {
                cursor: pointer;

                @media (max-width: 1199px) {
                    display: none;
                }
            }
        }
    }  
    
    .spacingLinkIcon {
        width: 30px;
        height: 30px;

        @media (max-width: 1199px) {
            display: none;
        }
    }

    .stockBoxLineIcon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 32px;

        &:after {
            content: "";
            border-bottom: 1.5px solid #0000001c;
            position: absolute;
            width: 93%;
            bottom: 0;
            left: -5px;

            @media (max-width: 1199px) {
                width: 100%;
                left: 0;
            }
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &.checker {
            &:last-child {
                &:after {
                    content: "";
                    display: none;
                }
            }

            .stockBoxlineLeft {
                display: flex;
                align-items: center;

                .stockBoxLineTitle {
                    margin-left: 10px;
                }
            }

            &:after {
                content: "";
                width: 82%;
                bottom: 0;
                left: 22px;

                @media (max-width: 1199px) {
                    width: 95%;
                }
            }
        }

        &.fullNoIcon {
            &:after {
                width: 105%;
            }
        }

        .stockBoxlineLeft {
            .stockBoxLineTitle {
                color: #000;
                font-size: 12px;
                font-weight: 500;

                @media (min-width: 1441px) and (max-width: 1700px) {
                    font-size: 11px;
                }
            }
        }

        .stockBoxLineRight {
            display: flex;
            align-items: center;

            &.redLine {
                .stockBoxLineValue {
                    color: #CE2020;
                } 
            }

            &.greenLine {
                .stockBoxLineValue {
                    color: #4F8000;
                } 
            }

            &.grey {
                pointer-events: none;
                
                .stockBoxLineValue {
                    color: #A59F9F;
                }
            }

            .greyPath {
                stroke: #A59F9F;
            }

            .stockBoxLineValue {
                color: #000;
                text-align: right;
                font-size: 13px;
                font-weight: 500;

                .fullNoIconFunc {
                    color: #4871D9;
                    text-decoration: underline;
                    cursor: pointer;
                }

                @media (min-width: 1441px) and (max-width: 1700px) {
                    font-size: 12px;
                }
            }

            .stockBoxLineCurrency {
                color: #A59F9F;
                font-size: 14px;
                font-weight: 500;
                margin-left: 3px;

                @media (min-width: 1441px) and (max-width: 1700px) {
                    font-size: 12px;
                }
            }
            
            .stockBoxLineLink {
                cursor: pointer;
                display: inline-block;
                margin-left: 3px;
                margin-top: 3px;
                margin-left: 10px;

                @media (max-width: 1199px) {
                    display: none;
                }
            }

            .stockBoxLineFunc {
                cursor: pointer;

                @media (max-width: 1199px) {
                    display: none;
                }
            }
        }

        @media (max-width: 1199px) {
            height: 30px;
        }
    }

    .stockBoxMobileOptions {
        margin-top: 30px;

        .stockBoxMobileOptionsHeader {
            display: flex;
            margin-bottom: 25px;
            position: relative;
            align-items: flex-start;

            &:after {
                content: "";
                background: #3BA3DD;
                height: 1px;
                width: 100%;
                position: absolute;
                bottom: 0;
            }

            span {
                color: rgba(0, 0, 0, 0.80);
                font-size: 12px;
                font-weight: 600;
                border-radius: 4px 4px 0px 0px;
                border-top: 1px solid #DBDBDB;
                border-right: 1px solid #DBDBDB;
                border-left: 1px solid #DBDBDB;
                background: #F2F2F2;
                padding: 9px;
                cursor: pointer;

                &:nth-child(2) {
                    margin: 0 2px;
                }

                &.active {
                    color: #000;
                    background: #fff;
                    border-top: 1px solid #3BA3DD;
                    border-right: 1px solid #3BA3DD;
                    border-left: 1px solid #3BA3DD;
                    padding: 12px 9px;
                }
            }
        }
    
        .stockMobileOption {
            display: none;
    
            &.active {
                display: inherit;
            }
        }
    }

    @media (max-width: 1440px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.topBannerNotice {
    background: rgb(79, 128, 0);
    position: fixed;
    right: 30px;
    padding: 10px;
    width: 180px;
    top: 65px;
    z-index: 9999;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

    span {
        span {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}


.popupUsedVehicleReport {
    .MuiDialog-paper {
        border-radius: 20px;

        .MuiIconButton-root {
            background: #C8C8C8;
            border-radius: 0;
            padding: 5px;
            margin-top: 20px;
            margin-right: 27px !important;

            .MuiIconButton-label {
                color: #fff;
            }
        }

        .popupUseVehicleFirstStage {
            .popupTitle {
                color: #5F83DE;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: flex-end;
                margin-bottom: 20px;

                svg {
                    margin-right: 10px;

                    path {
                        stroke: #5F83DE;
                    }
                }
            }

            .popupVehicleDesc {
                color: #000;
                font-size: 18px;
                font-weight: 500;
                display: block;
                margin-bottom: 5px;
                width: 565px;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            .vehicleDetail {
                color: #A59F9F;
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;
                position: relative;
                padding-left: 5px;
                margin-right: 10px;

                &:before {
                    content: "";
                    width: 2px;
                    height: 2px;
                    display: inline-block;
                    background: #000;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:last-child {
                    margin-right: 0;
                }

                span {
                    color: #000;
                }

                @media (max-width: 767px) {
                    font-size: 11px;
                }
            }

            .vehicleDetailsUpdate {
                margin-top: 15px;
                padding-top: 15px;
                border-top: 1px solid #ddd;
                display: flex;
                margin-bottom: 20px;

                .vehicleDetailsUpdateLeft, .vehicleDetailsUpdateRight {
                    width: 50%;
                    margin-right: 30px;

                    .vehicleCurrentValue {
                        color: #000;
                        font-size: 26px;
                        font-weight: 700;
                        display: block;

                        @media (max-width: 767px) {
                            font-size: 20px;
                        }
                    }

                    .vehicleCurrentTitle {
                        color: #000;
                        font-size: 14px;
                        font-weight: 500;
                        display: block;
                        margin-bottom: 5px;

                        @media (max-width: 767px) {
                            font-size: 12px;
                        }
                    }

                    .vehicleLastUpdated, .vehicleLastUpdated {
                        color: #A59F9F;
                        font-size: 12px;
                        font-weight: 500;
                        display: block;

                        @media (max-width: 767px) {
                            font-size: 10px;
                        }
                    }

                    .updateBoxWrp {
                        margin-top: 15px;

                        .updateBoxTitle {
                            color: #000;
                            font-size: 14px;
                            font-weight: 500;
                            display: block;
                            margin-bottom: 3px;

                            @media (max-width: 767px) {
                                font-size: 11px;
                            }
                        }

                        .updateBoxInr {
                            position: relative;

                            .MuiInputBase-root {
                                width: 100%;
                                border-radius: 5px;
                                border: 2px solid rgba(0, 0, 0, 0.17);

                                input {
                                    max-width: 50%;
                                    padding: 10px;
                                }

                                &::after, &::before {
                                    display: none;
                                }
                            }

                            .updateBoxDif {
                                position: absolute;
                                right: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                font-size: 14px;
                                font-weight: 500;

                                &.green {
                                    color: #4F8000;
                                }

                                &.red {
                                    color: #CE2020;
                                }
                            }
                        }
                    }
                }

                .vehicleDetailsUpdateRight {
                    margin-right: 0;
                }
            }

            .popupSaveButtons {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 15px;

                span {
                    border-radius: 5px;
                    display: flex;
                    width: 145px;
                    height: 38px;
                    padding: 5px 17px;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
                    color: #5336CF;
                    cursor: pointer;
                    transition: 0.2s all;

                    svg {
                        margin-right: 5px;
                    }

                    &:hover {
                        background: #f4f1ff;
                    }

                    &.save {
                        background: #DCD5FB;
                        margin-right: 15px;

                        &:hover {
                            background-color: #d1c8f9;
                        }
                    }

                    @media (max-width: 767px) {
                        font-size: 12px;
                    }
                }
            }
        }

        .popupUseVehicleConfirmation {
            .confirmIcon {
                width: 42px;
                height: 42px;
                color: #CE2020;
                font-size: 26px;
                font-weight: 500;
                border: 3px solid #CE2020;
                display: block;
                border-radius: 50%;
                text-align: center;
                line-height: 1.6;
                margin: 0 auto 20px;
            }

            .confirmText {
                color: #2F2D2D;
                font-size: 18px;
                font-weight: 500;
                display: block;
                text-align: center;
                border-bottom: 1px solid #d7d7d7;
                padding-bottom: 10px;
                margin-bottom: 15px;
                width: 560px;
            }

            .confirmSub {
                color: #000;
                font-size: 14px;
                font-weight: 500;
                display: block;
                text-align: center;
                max-width: 480px;
                margin: 0 auto 20px;
            }

            .confirmPriceRange {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;

                .value {
                    color: #000;
                    font-size: 26px;
                    font-weight: 700;
                }

                svg {
                    margin: 0 15px;
                }

                .change  {
                    font-size: 14px;
                    font-weight: 500;

                    span {
                        margin-left: 5px;
                    }

                    &.red {
                        color: #CE2020;
                    }

                    &.green {
                        color: #4F8000;;
                    }
                }
            }

            .confirmPriceBtns {
                display: flex;
                justify-content: center;
                margin-bottom: 50px;

                span {
                    border-radius: 5px;
                    display: flex;
                    width: 145px;
                    height: 38px;
                    padding: 5px 17px;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
                    color: #5336CF;
                    cursor: pointer;
                    transition: 0.2s all;
                
                    &:hover {
                        background: #f4f1ff;
                    }
                
                    &.confirm {
                        background: #DCD5FB;
                        margin-right: 15px;
            
                        &:hover {
                            background-color: #d1c8f9;
                        }
                    }
            
                    @media (max-width: 767px) {
                        font-size: 12px;
                    }                
                }
            }
        }
    }
}

.lastUpdatedWrp {
    text-align: right;  
    margin-bottom: 30px;

    .lastUpdated {
        padding: 10px;
        background: #fff;
        border-radius: 10px;
        color: #000;
        font-weight: 500;

        span {
            color: #4F8000;
            font-weight: 600;
        }
    }
}