.hiddenScroll {
    overflow: hidden !important;
}
.btn_add {
    margin-top: 20px !important;
}
.customizedButton {
    position: 'absolute';
    left: '95%';
    top: '-9%';
    background-color: 'lightgray';
    color: 'gray';
}
.setMargin {
    margin: 8px !important;
}
.setTopmargin {
    margin-top: 27px !important;
}
.SetButtonColor {
    color: red !important;
}
.setHeight {
    height: 2.3em !important;
}
.btninActive {
    margin: 8px 0px 8px 0px !important;
    height: 43px !important;
    width: 97% !important;
}
.btnActive {
    margin: 8px 0px 8px 0px !important;
    height: 43px !important;
    border: 1px solid green !important;
    width: 97% !important;
}
.btnActiveNo {
    margin: 8px 0px 8px 0px !important;
    height: 43px !important;
    border: 1px solid red !important;
    width: 97% !important;
}
.btninActiveNoMargin {
    height: 43px !important;
    width: 95% !important;
}
.btnActiveNoMagin {
    height: 43px !important;
    border: 1px solid green !important;
    width: 95% !important;
}
.btnActiveNoNoMagin {
    height: 43px !important;
    border: 1px solid red !important;
    width: 95% !important;
}

.btnActiveNA {
    margin: 8px !important;
    height: 43px !important;
    border: 1px solid orange !important;
    width: 97% !important;
}

.btninActive1 {
    margin: 8px !important;
    height: 33px !important;
    width: 97% !important;
}
.btnActive1 {
    margin: 8px !important;
    height: 33px !important;
    border: 1px solid green !important;
    width: 97% !important;
}
.btnActiveNo1 {
    margin: 8px !important;
    height: 33px !important;
    border: 1px solid red !important;
    width: 97% !important;
}

.btnActiveNA1 {
    margin: 8px !important;
    height: 33px !important;
    border: 1px solid orange !important;
    width: 97% !important;
}

.setFullWidth {
    width: 100% !important;
}
.gridFont {
    font-size: 21px !important;
}
.setStandardWidth {
    width: 1080px !important;
}
.setWorkflowHight {
    min-height: 720px !important ;
    max-height: 720px !important;
}
.ml-2 {
    margin-left: 2px !important;
}
