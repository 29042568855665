.buttonValue {
    background-color: #ffffff;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 0.8125rem !important;
    border: 0.0625rem solid #ffffff;
    height: 3.25rem;
    // width: 10.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    color: #522bd2;
    font-weight: 600;
    box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}

.sendBtn:hover {
    background-color: #569101;
}

.buttonValue:hover {
    // background-color: #0e2132;
    background-color: #dcd4fc;
    border: 0.0625rem solid #522bd2;
    box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}

.buttonValueActive {
    background-color: #dcd4fc;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 0.8125rem !important;
    border: 0.0625rem solid #dcd4fc;
    height: 3.25rem;
    // width: 10.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    color: #522bd2;
    font-weight: 600;
    box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}

.iconButtonValue {
    background-color: #ffffff;
    cursor: pointer;
    // margin-top: 0.25rem;
    font-family: Montserrat;
    font-size: 0.9375rem !important;
    border: 0.0625rem solid #522bd2;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    color: #522bd2;
    font-weight: 600;
    // box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}

.iconButtonValue:hover {
    // background-color: #0e2132;
    background-color: #dcd4fc;
    border: 0.0625rem solid #522bd2;
    box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}

.iconButtonValueActive {
    background-color: #dcd4fc;
    cursor: pointer;
    margin-top: 0.1875rem;
    font-family: Montserrat;
    font-size: 0.9375rem !important;
    border: 0.0625rem solid #dcd4fc;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    color: #522bd2;
    font-weight: 600;
    box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}

.sendBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.9rem;
    height: 0.9rem;
    background: black;
    padding: 0.3125rem;
    border-radius: 100%;
    // color: white;
    text-align: center;
    cursor: pointer;
    .btnicon {
        color: white;
        font-size: 0.8375rem;
        font-weight: bold;
    }
}

.smallsendBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    background: black;
    padding: 0.3125rem;
    border-radius: 100%;
    // color: white;
    text-align: center;
    cursor: pointer;
    .btnicon {
        color: white;
        font-size: 0.9375rem;
        font-weight: bold;
    }
}

.linkedButton {
    background-color: #989898;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 0.9375rem !important;
    border: 0.0625rem solid #989898;
    height: 3.25rem;
    width: 15.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    color: #ffffff;
    // font-weight: 600;
    box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}

.linkedButton:hover {
    // background-color: #0e2132;
    background-color: #569101;
    border: 0.0625rem solid #569101;
    box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}

.smalllinkedButton {
    background-color: #989898;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 0.625rem !important;
    border: 0.0625rem solid #989898;
    height: 1.55rem;
    width: 6.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125rem;
    color: #ffffff;
    // font-weight: 600;
    box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}

.smalllinkedButton-active {
    background-color: #569101;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 0.625rem !important;
    border: 0.0625rem solid #569101;
    height: 1.55rem;
    width: 6.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125rem;
    color: #ffffff;
    // font-weight: 600;
    box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}

.smalllinkedButton:hover {
    // background-color: #0e2132;
    background-color: #569101;
    border: 0.0625rem solid #569101;
    box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}

@media screen and (max-width: 1680px) {
    .linkedButton {
        height: 3rem;
        width: 250px;
    }
    .smalllinkedButton {
        width: 100%; //!important;
        padding: 0px 3px;
        font-size: 0.5rem !important;
        height: 1.2rem;
    }
    .smalllinkedButton-active {
        width: 100%; //!important;
        padding: 0px 3px;
        font-size: 0.5rem !important;
        height: 1.2rem;
    }
}

@media screen and (max-width: 1440px) {
    .linkedButton {
        height: 2.7rem;
    }
    .buttonValue {
        height: 2.6rem;
        font-size: 0.75rem !important;
    }
    .buttonValueActive {
        height: 2.6rem;
        font-size: 0.75rem !important;
    }
    .smalllinkedButton {
        width: 100%; //!important;
        padding: 0px 3px;
        font-size: 0.5rem !important;
        height: 1.2rem;
    }
}

@media screen and (max-width: 1280px) {
    .smalllinkedButton {
        font-size: 9px !important;
        padding: 2px;
        width: 5.3125rem;
    }
    .buttonValue {
        font-size: 0.8125rem !important;
    }
    .buttonValueActive {
        font-size: 0.8125rem !important;
    }
    .sendBtn {
        width: 0.5rem;
        height: 0.5rem;
        cursor: pointer;
    }
    .linkedButton {
        height: 2.1rem;
        font-size: 0.75rem !important;
    }

    .buttonValue {
        height: 2.5rem;
        font-size: 0.75rem !important;
    }
    .buttonValueActive {
        height: 2.5rem;
        font-size: 0.75rem !important;
    }
}

@media screen and (max-width: 1024px) {
    .smalllinkedButton-active {
        font-size: 0.5rem !important;
        width: 5.3125rem;
    }
    .linkedButton {
        height: 2.5rem;
        width: 2.5rem;
        margin: auto;
    }
    .buttonValue {
        height: 2rem;
        font-size: 0.75rem !important;
    }
    .buttonValueActive {
        height: 2rem;
        font-size: 0.75rem !important;
    }

    .sendBtn {
        width: 10px;
        height: 10px;
        cursor: pointer;
        .btnicon {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 750px) {
    .buttonValue {
        font-size: 0.625rem !important;
    }
    .buttonValueActive {
        font-size: 0.625rem !important;
    }
    .AvtarIcon {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.MuiDialog-paper {
    margin: 10px !important;
    position: relative;
    overflow-y: auto;
}

.salesDaysBtn {
    background-color: #ffffff;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 0.9375rem !important;
    border-radius: 50% !important;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 0.375rem;
    color: black;
    font-weight: 600;
}

.salesDaysBtn-active {
    background-color: #dcd4fc;
    cursor: pointer;
    font-family: Montserrat;
    border-radius: 50% !important;
    font-size: 0.9375rem !important;
    border: 0.0625rem solid #522bd2;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 0.3125rem;
    color: #522bd2;
    font-weight: 600;
}

.salesDaysBtn:hover {
    // background-color: #0e2132;
    background-color: #dcd4fc;
    border: 0.0625rem solid #522bd2;
    // box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
}
