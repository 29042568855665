.boxSkel {
    padding: 0 30px 30px;

    .titleBoxSkel {
        margin-top: 40px;
        margin-bottom: 30px;
        color: #4F8000;
        font-size: 28px;
        font-weight: 400;
        display: flex;
        align-items: center;

        .MuiSwitch-thumb {
            color: #757575;
        }

        .MuiSwitch-colorSecondary.Mui-checked {
            .MuiSwitch-thumb {
                color: #4F8000;
            }
        }

        .MuiSwitch-track {
            background-color: #b1b1b1;
        }

        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
            background-color: #b1b1b1;
        }

        .MuiFormControlLabel-root {
            color: #000;
            display: flex;

            .MuiSwitch-root {
                order: 2;

                .MuiSwitch-input {
                    left: 0;
                    width: 10px;
                }

                .MuiButtonBase-root {
                    color: #4F8000;

                    @media (max-width: 767px) {
                        padding-left: 0;
                    }
                }

                .MuiSwitch-track {
                    background-color: #b1b1b1;
                    opacity: 1;
                }

                @media (max-width: 767px) {
                    padding-left: 0;
                }
            }

            @media (max-width: 767px) {
                justify-content: start;
                margin-left: 0;
            }
        }

        @media (max-width: 767px) {
            display: block;
        }
    }

    .selectedFilterOptions {
        text-align: left;
        margin-top: 15px;
        display: flex;

        .selectedClean {
            display: inline-flex;
            margin-right: 20px;
            background-color: #fff;
            height: 45px;
            align-items: center;
            padding: 0 17px;
            border-radius: 10px;
            margin-top: 4px;
            width: 160px;

            .selectedText {
                color: #000;
                font-size: 16px;
                font-weight: 500;

                .selectedClear {
                    color: #4871D9;
                    font-size: 12px;
                    font-weight: 500;
                    margin-left: 10px;
                    cursor: pointer;

                    span {
                        font-size: 20px;
                        margin-left: 5px;
                        margin-top: -4px;
                        display: inline-block;
                        font-weight: 600;
                    }
                }
            }
        }

        .selectedFilters {
            width: 83%;
            padding: 10px 45px 10px 17px;
            max-height: 35px;
            display: inline-flex;
            border-radius: 10px;
            background-color: #fff;
            position: relative;
            transition: 0.2s all;
            overflow: hidden;
            flex-wrap: wrap;

            &.dropdownOpen {
                max-height: 500px;
                padding: 10px 45px 1px 17px;

                .dropdownArrow {
                    transform: rotate(180deg);
                }
            }

            .selectedOption {
                margin-bottom: 10px;
                display: flex;
                padding: 2px 17px;
                align-items: center;
                border-radius: 2px;
                background: rgba(72, 113, 217, 0.17);
                height: 32px;
                margin-right: 16px;

                &:last-child { 
                    margin-left: 0;
                }

                span {
                    color: rgba(0, 0, 0, 0.47);
                    text-align: center;
                    font-size: 11px;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                .removeBranch {
                    color: #000;
                    font-weight: 700;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }

            .dropdownArrow {
                position: absolute;
                right: 17px;
                top: 20px;
                cursor: pointer;
                transition: 0.2s all;
            }   
        }

        @media (max-width: 1199px) {
            display: none;
        }
    }   

    .selectedBranchList {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .selectedBranch {
            background-color: #fff;
            margin-right: 7px;
            padding: 7px 17px;
            display: block;
            margin-bottom: 10px;

            span {
                color: rgba(0, 0, 0, 0.47);
                text-align: center;
                font-size: 11px;
                font-weight: 700;
                text-transform: uppercase;

                &.removeBranch {
                    color: #000;
                    font-weight: 700;
                    text-transform: uppercase;
                    cursor: pointer;
                    margin-left: 8px;
                }
            }
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    @media (max-width: 767px) {
        padding: 0 15px 30px;
    }
}

.salesExecPerformance {
    height: 730px;
    overflow: hidden;

    @media (max-width: 1599px) {
        height: 650px;
    }
}

.salesExecTable {
    margin-top: 60px;
    text-align: left;

    .salesExecTitle {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        display: block;
        margin-bottom: 10px;

        @media (min-width: 1200px) {
            font-size: 20px;
        }
    }

    .salesExecSub {
        font-size: 14px;
        display: block;
        margin-bottom: 20px;

        @media (max-width: 767px) {
            display: none;
        }
    }

    .MuiTable-root {
        background-color: #fff;

        .MuiTableHead-root {
            .MuiTableCell-head {
                background: #F5F5F5;
                border-bottom: 1px solid #000;
                padding: 10px 15px;
                line-height: 1;
                width: 7%;

                &:first-child {
                    width: 30%;
                }

                span {
                    color: #000;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .MuiTableBody-root {
            .MuiTableCell-root {
                padding: 10px 15px;
                line-height: 1;
                color: #000;
                font-size: 12px;

                .tableItemGreen {
                    color: #4F8000;
                    display: inline-block;
                    margin-left: 3px;
                }

                .finPenTable {
                    color: #00B112;
                    border-radius: 2px;
                    font-weight: 700;
                    background: #ECFFEE;
                    padding: 0px 5px;
                }
            }
        }
    }

    .salesExecSwitch {
        margin-bottom: 30px;
        padding-left: 3px;

        .MuiSwitch-root {
            width: 57px;
            height: 34px;
        }

        .MuiSwitch-thumb {
            width: 15px;
            height: 15px;
            color: #757575;
        }

        .MuiSwitch-colorSecondary.Mui-checked {
            .MuiSwitch-thumb {
                color: #4F8000;
            }
        }

        .MuiSwitch-track {
            background-color: #b1b1b1;
        }

        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
            background-color: #b1b1b1;
        }

        .MuiTypography-body1 {
            font-size: 10px;

            @media (min-width: 1200px) {
                font-size: 14px;
            }
        }
    }

    @media (max-width: 1199px) {
        margin-top: 30px;
    }
}

.largeBoxGrid {
    border-radius: 20px;
    border: 0px solid rgba(177, 5, 5, 0.10);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    padding: 30px;
    margin-top: 25px !important;

    .divider {
        width: 0.5px;
        height: 109px;
        background-color: #000;

        &.hidden-lg {
            @media (max-width: 1199px) {
                display: none;
            }
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    .MuiGrid-container {
        @media (min-width: 1200px) {
            justify-content: space-between;
        }
    }

    @media (max-width: 1199px) {
        margin-top: 30px !important;
    }

    @media (max-width: 767px) {
        padding: 20px;
        margin-top: 40px !important;
    }
}

.barWrapperSP {
    width: 69%;
    margin-left: 20px;

    @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }
}

.TotalUnitSP {
    text-align: left;
    margin-right: 50px;

    &.normalType {
        // width: 15%;
        // margin-right: 40px;

        // @media (max-width: 1799px) {
        //     margin-right: 40px;
        // }

        // @media (max-width: 1599px) {
        //     margin-right: 20px;
        // }

        // @media (max-width: 1199px) {
        //     margin-right: 40px;
        // }

        @media (max-width: 767px) {
            width: 100%;
            margin: 0;
        }
    }

    .mainValue {
        color: #4F8000;
        font-size: 32px;
        font-weight: 700;
        display: block;
        margin-bottom: 5px;

        &.redValue {
            color: #CE2020;
        }

        @media (max-width: 1500px) {
            font-size: 25px;
        }

        @media (max-width: 767px) {
            display: inline;
        }
    }

    .mainLabel {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        display: block;

        @media (max-width: 1700px) {
            font-size: 14px;
        }

        @media (max-width: 1500px) {
            font-size: 13px;
        }

        @media (max-width: 767px) {
            display: inline;
            margin-left: 8px;
            margin-right: 5px;
        }
    }

    .loweLabel {
        color: #A59F9F;
        font-size: 14px;
        display: block;
        margin-bottom: 15px;
        font-weight: 500;
 
        @media (max-width: 1700px) {
            font-size: 12px;
        }

        @media (max-width: 1500px) {
            font-size: 10px;
        }

        @media (max-width: 1199px) {
            margin-bottom: 10px;
        }

        @media (max-width: 767px) {
            margin-bottom: 5px;
            display: inline;
        }
    }

    .compValues {
        .avgValue {
            color: #A59F9F;
            font-size: 14px;
            display: block;
            font-weight: 500;
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            svg {
                width: 16px;
                height: 16px;
                margin-right: 5px;
            }

            span {
                color: #000;
                margin-left: 5px;

                &.redLine {
                    color: #CE2020;
                }

                &.greenLine {
                    color: #4F8000;
                }
            }

            @media (max-width: 1500px) {
                font-size: 12px;
            }

            @media (max-width: 767px) {
                font-size: 10px;
                margin-right: 30px;
            }
        }
        
        .priorYear {
            color: #00C48B;
            font-size: 13px;
            font-weight: 500;
            display: flex;
            align-items: center;

            svg {
                width: 16px;
                height: 16px;
                margin-right: 5px;
            }

            &.redValue {
                color: #CE2020;
            }

            @media (max-width: 1890px) {
                font-size: 12px;
            }

            @media (max-width: 767px) {
                font-size: 10px;
                display: inline-flex;
            }
        }

        @media (max-width: 767px) {
            display: flex;
            align-items: center;
        }
    }
    
    @media (max-width: 1700px) {
        margin-right: 20px;
    }

    @media (max-width: 1500px) {
        margin-right: 10px;
    }

    @media (max-width: 1199px) {
        margin-right: 40px;
        width: 15%;
    }

    @media (max-width: 767px) {
        margin-right: 0px;
        width: 100%;
    }
}

.progressBarSPwrp {
    text-align: left;
    margin: 0 30px;
    
    .mainTitle {
        color: #000;
        font-size: 16px;
        text-transform: capitalize;
        display: block;
        font-weight: 500;
        margin-bottom: 15px;

        @media (max-width: 1500px) {
            font-size: 12px;
        }
    }

    .progressBarSP {
        width: 350px;
        height: 37px;
        display: flex;
        margin-bottom: 15px;
        
        .progressBar {
            height: 100%;

            .progressBartooltip {
                position: relative;
                height: 100%;

                &:hover {
                    cursor: pointer;

                    span {
                        opacity: 1;
                    }
                }

                span {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    text-align: center;
                    color: #fff;
                    font-weight: bold;
                    opacity: 0;
                    transition: 0.2s all;
                }
            }

            &.pb-left {
                background: #14B8A6;
            }

            &.pb-mid {
                background: #6366F1;
            }

            &.pb-right {
                background: rgba(0,0,0,0.7);
            }
        }

        @media (max-width: 1800px) {
            width: 250px;
        }

        @media (max-width: 1500px) {
            width: 200px;
        }
    }

    .progressBarSPTitles {
        display: flex;
        justify-content: space-between;

        .barTitles {
            font-size: 16px;
            font-weight: 500;
            font-weight: bold;

            .titleInitial {
                border-radius: 50%;
                padding: 1px 4px;
                font-weight: normal;
            }

            &:first-child {
                color: #41C5C3;

                .titleInitial {
                    background-color: #41C5C3;
                    color: #fff;
                }

                span {
                    color: #41C5C3;
                }
            }

            &:nth-child(2) {
                color: #6366F1;
                margin: 0 5px;

                .titleInitial {
                    background-color: #6366F1;
                    color: #fff;
                }

                span {
                    color: #6366F1;
                }
            }

            &:last-child {
                color: rgba(0,0,0,0.7);

                .titleInitial {
                    background-color: rgba(0,0,0,0.7);
                    color: #fff;
                }

                span {
                    color: rgba(0,0,0,0.7);

                }
            }


            @media (max-width: 1700px) {
                font-size: 12px;
            }

            @media (max-width: 1500px) {
                font-size: 10px;
            }
        }
    }

    @media (max-width: 1800px) {
        margin: 0 5px;
    }

    @media (max-width: 1199px) {
        display: none;
    }
}

.unitValueSP {
    &.mainType {
        margin: 0 30px;

        .mainValue {
            color: #4F8000;
            font-size: 28px;
            font-weight: 700;
            display: block;
            margin-bottom: 5px;
            
            &.redValue {
                color: #CE2020;
            }

            @media (max-width: 1500px) {
                font-size: 20px;
            }

            @media (max-width: 1199px) {
                font-size: 13px;
                order: 2;
                width: 20%;
                text-align: left;
                font-weight: 500;
            }

            @media (max-width: 767px) {
                font-size: 12px;
            }
        }

        .mainTitle {
            color: #000;
            font-size: 16px;
            font-weight: 500;
            display: block;
            margin-bottom: 5px;

            @media (max-width: 1700px) {
                font-size: 14px;
            }

            @media (max-width: 1500px) {
                font-size: 13px;
            }

            @media (max-width: 1199px) {
                font-size: 13px;
                order: 1;
                width: 45%;
                text-align: left;
            }

            @media (max-width: 767px) {
                font-size: 12px;
            }
        }

        .val1Line {
            display: block;
            color: #A59F9F;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 16px;
                height: 16px;
                margin-right: 5px;

                @media (max-width: 767px) {
                    display: none;
                }
            }

            span {
                color: #000;
                margin-left: 5px;

                &.redLine {
                    color: #CE2020;
                }

                &.greenLine {
                    color: #4F8000;
                }
            }

            @media (max-width: 1500px) {
                font-size: 12px;
            }

            @media (max-width: 1199px) {
                font-size: 13px;
                order: 3;
                width: 35%;
                text-align: right;
            }

            @media (max-width: 767px) {
                font-size: 12px;
                display: block;
            }
        }

        .linkRedirect {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;

            @media (max-width: 1500px) {
                font-size: 12px;
            }

            @media (max-width: 1199px) {
                display: none;
            }
        }

        .val2Line {
            display: block;
            color: #4F8000;
            font-size: 14px;
            font-weight: 500;

            &.redValue {
                color: #CE2020;
            }

            @media (max-width: 1500px) {
                font-size: 12px;
            }

            @media (max-width: 1199px) {
                display: none;
            }
        }

        @media (max-width: 1500px) {
            margin: 0 10px;
        }

        @media (max-width: 1199px) {
            margin: 0px;
            display: flex;
            justify-content: space-between;
            border-bottom: 0.5px solid #C8C8C8;
            margin-bottom: 5px;

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
            }
        }

        @media (max-width: 767px) {
            padding-bottom: 5px;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    &.normalType {
        display: flex;
        border-bottom: 0.5px solid #C8C8C8;
        margin-bottom: 5px;

        .mainValue {
            color: #4F8000;
            font-size: 16px;
            font-weight: 500;
            display: block;
            order: 2;
            width: 20%;
            text-align: left;

            &.wpenLine {
                width: 10%;
            }
            
            &.redValue {
                color: #CE2020;
            }

            .colourBlack {
                color: #000 !important;
            }

            .colourRed {
                color: #CE2020 !important;
            }

            @media (max-width: 1599px) {
                font-size: 13px;
            }

            @media (max-width: 1199px) {
                margin-bottom: 5px;
            }

            @media (max-width: 767px) {
                font-size: 12px;
            }
        }

        .penValue {
            order: 4;
            width: 15%;

            span {
                color: #00B112;
                border-radius: 2px;
                font-weight: 700;
                background: #ECFFEE;
                padding: 0px 5px;
            }

            &.redColour {
                span {
                    background: #d1212117;
                    color: #CE2020;
                }
            }
        }

        .mainTitle {
            color: #000;
            font-size: 16px;
            font-weight: 500;
            display: block;
            order: 1;
            width: 45%;
            text-align: left;

            &.wpenLine {
                order: 0;
            }

            @media (max-width: 1599px) {
                font-size: 13px;
            }

            @media (max-width: 1199px) {
                margin-bottom: 5px;
            }

            @media (max-width: 767px) {
                font-size: 12px;
            }
        }

        .val1Line {
            display: block;
            color: #A59F9F;
            font-size: 14px;
            font-weight: 500;
            order: 3;
            width: 35%;
            text-align: right;

            &.wpenLine {
                order: 4;
                width: 30%;
            }

            span {
                color: #000;

                &.redLine {
                    color: #CE2020;
                }

                &.greenLine {
                    color: #4F8000;
                }
            }

            @media (max-width: 1599px) {
                font-size: 13px;
            }

            @media (max-width: 1199px) {
                margin-bottom: 5px;
            }

            @media (max-width: 767px) {
                font-size: 12px;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    @media (max-width: 767px) {
        padding-bottom: 5px;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.summaryBoxes {
    margin-top: 70px !important;
    display: flex;

    .summaryBox {
        width: 50%;

        &:first-child {
            @media (min-width: 1200px) {
                margin-right: 20px;
            }

            @media (max-width: 1199px) {
                margin-bottom: 60px;
            }

            @media (max-width: 767px) {
                margin-bottom: 20px;
            }
        }

        .summaryBoxNav {
            display: flex;

            .summaryBoxItem {
                width: 25%;
                height: 80px;
                background-color: #A59F9F;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
                margin-bottom: -20px;
                transition: 0.2s all;
                cursor: pointer;
                position: relative;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;

                &.summaryOpen {
                    background-color: #4F8000;
                    box-shadow: (0px 4px 4px rgba(0, 0, 0, 0.10));
                    height: 110px;
                    margin-top: -30px;

                    svg {
                        display: block;
                        width: 100%;
                        width: 37px;
                        margin: 0 auto 5px;
                        
                        path {
                            fill: #fff;
                            stroke: #fff;
                        }
                    }

                    &:hover {
                        margin-top: -30px;
                    }

                    @media (max-width: 1599px) {
                        height: 90px;
                        margin-top: -35px;
                    }
                }

                &:hover {
                    margin-top: -10px;
                    background-color: #4F8000;
                }

                svg {
                    display: none;
                }

                .summaryBoxTitle {
                    width: 100%;
                    position: absolute;
                    top: 45%;
                    transform: translateY(-50%);

                    span {
                        color: #FFF;
                        font-size: 16px;
                        font-weight: 500;
                        display: block;
                        text-transform: uppercase;

                        @media (max-width: 1599px) {
                            font-size: 14px;
                        }
                    }
                }

                @media (max-width: 1599px) {
                    height: 60px;
                    margin-bottom: -15px;
                }
            }

            @media (max-width: 767px) {
                display: none;
            }
        }

        .summaryBoxWRP {
            border-radius: 20px;
            border: 0px solid rgba(177, 5, 5, 0.10);
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
            padding: 30px 30px 20px; 
            z-index: 2;
            position: relative;
            height: 383px;
            overflow-y: auto;

            .summaryBoxInr {
               display: none;

               &.summaryBoxVisible {
                display: block;
               }

                .divider {
                    width: 0.5px;
                    height: 130px;
                    background-color: #000;
                    margin-top: 5px;

                    @media (max-width: 1199px) {
                        height: 120px;
                        margin-top: 10px;
                    }
            
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
    
                .summaryBoxTitle {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;

                    .summaryBoxTitleLink {
                        cursor: pointer;
                    }
    
                    span {
                        color: #000;
                        font-size: 21px;
                        font-weight: 500;
    
                        @media (max-width: 1599px) {
                            font-size: 17px;
                        }
    
                        @media (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
    
                    @media (max-width: 1199px) {
                        margin-bottom: 10px;
                    }
    
                    @media (max-width: 767px) {
                        margin-bottom: 5px;
                    }
                }

                .summaryBreakdownWRP {
                    .summaryMainTile {
                        color: #474747;
                        font-size: 14px;
                        font-weight: 600;
                        display: block;
                        margin: 14px 0 10px;
                        text-align: left;

                        @media (min-width: 768px) {
                            display: none;
                        }
                    }
                    
                    .summaryBreakdownItem {
                        .summaryBreakdownDesk {
                            margin-top: 30px;
                            margin-right: 20px;
                            text-align: left;
    
                            &:last-child {
                                margin-right: 0;
                            }
    
                            .summaryBreakdownTop {
                                background: #E1E1E1;
                                padding: 10px;
                                position: relative;
    
                                .summaryTitle {
                                    color: #000;
                                    font-size: 14px;
                                    font-weight: 500;
                                    text-transform: uppercase;
    
                                    @media (max-width: 1630px) {
                                        font-size: 11px;
                                    }
    
                                    @media (max-width: 1345px) {
                                        font-size: 9px;
                                    }
    
                                    @media (max-width: 1199px) {
                                        font-size: 11px;
                                    }
                                }
    
                                .summaryCases {
                                    color: #4F8000;
                                    font-size: 18px;
                                    font-weight: 600;
    
                                    span:first-child {
                                        margin-right: 5px;
                                    }
    
                                    @media (max-width: 1630px) {
                                        font-size: 14px;
                                    }
                                }
    
                                .summaryAvg {
                                    color: #A59F9F;
                                    font-size: 14px;
                                    font-weight: 500;
    
                                    span:first-child {
                                        margin-right: 5px;
                                    }
    
                                    @media (max-width: 1630px) {
                                        font-size: 11px;
                                    }
                                }
    
                                .summarySvg {
                                    position: absolute;
                                    width: 30px;
                                    height: 30px;
                                    top: 10px;
                                    right: 10px;
    
                                    @media (max-width: 1630px) {
                                        right: 2px;
                                    }
                                    
                                    @media (max-width: 1199px) {
                                        top: 3px;
                                        right: 3px;
                                    }
                                }
    
                                @media (max-width: 1630px) {
                                    padding: 10px 5px;
                                }
    
                                @media (max-width: 1199px) {
                                    padding: 3px;
                                }
                            }
    
                            .summaryBreakdownBtm {
                                background: rgba(200, 200, 200, 0.20);
                                padding: 10px;
        
                                .summaryValue {
                                    color: #000;
                                    font-size: 16px;
                                    font-weight: 400;
    
                                    span:first-child {
                                        margin-right: 5px;
                                        font-weight: 600;
                                    }
    
                                    @media (max-width: 1630px) {
                                        font-size: 12px;
                                    }
                                }
                                
                                .summaryPenetration {
                                    position: relative;

                                    span {
                                       
                                    }

                                    &.tooltipWrp {
                                        span {
                                            &:first-child {
                                                color: #A59F9F;
                                                font-size: 12px;
                                                font-weight: 500;
                                                text-transform: uppercase;
                                                display: block;
        
                                                @media (max-width: 1630px) {
                                                   font-size: 10px;
                                                }
                                            }
        
                                            &:nth-child(2) {
                                                width: 127px;
                                                height: 8px;
                                                background: #4F8000;
                                                display: inline-block;
                                                margin-right: 9px;
                                            }

                                            &:nth-last-child(2) {
                                                color: #000;
                                                font-size: 14px;
                                                font-weight: 600;
                                                float: right;

                                                @media (max-width: 1599px) {
                                                    font-size: 10px;
                                                    position: absolute;
                                                    right: 0;
                                                    bottom: 2px;
                                                }
                                            }
                                        }
                                    }
                                }
    
                                @media (max-width: 1630px) {
                                    padding: 10px 5px;
                                }
                            }
                        } 

                        &:last-child {
                            .summaryBreakdownMob {
                                padding-bottom: 0;
                                margin-bottom: 0;
                                border-bottom: none;
                            }
                        }

                        .summaryBreakdownMob {
                            display: flex;
                            justify-content: space-between;
                            border-bottom: 0.5px solid #C8C8C8;
                            margin-bottom: 5px;
                            padding-bottom: 5px;

                            .summaryTitle {
                                color: #000;
                                font-size: 12px;
                                font-weight: 500;
                                width: 45%;
                                text-align: left;
                            }

                            .summaryCases {
                                color: #4F8000;
                                font-size: 12px;
                                font-weight: 500;
                                width: 20%;
                                text-align: left;

                                span:last-child {
                                    margin-left: 3px;
                                }
                            }

                            .summaryValue {
                                color: #A59F9F;
                                font-size: 12px;
                                font-weight: 400;
                                width: 35%;
                                text-align: right;

                                span:last-child {
                                    font-weight: 600;
                                    color: #000;
                                    margin-left: 3px;
                                }
                            }
                        }

                        @media (min-width: 768px) {
                            width: 23%;
                        }
                    }

                    @media (max-width: 767px) {
                        display: block;
                    }
                }

                .summaryTable {
                    margin-top: 20px;
                    display: block;
                    height: 164px;
                    overflow-y: auto;

                    .popupOpenBtn {
                        svg {
                            cursor: pointer;
                            margin-left: 10px;
                            width: 12px;
                            height: 12px;
                        }
                    }

                    &.finType {
                        table {
                            tr {
                                td {
                                    &:last-child {
                                        color: #00B112;
                                        border-radius: 2px;
                                        font-weight: 700;
                                        
                                        span {
                                            background: #ECFFEE;
                                            padding: 2px 8px;
                                        }
                                    }
                                }
                            }
                        }

                        &.lastDigit {
                            table {
                                tr {
                                    td {
                                        &:last-child, &:nth-last-child(2) {
                                            color: #00B112;
                                            border-radius: 2px;
                                            font-weight: 700;

                                            span {
                                                background: #ECFFEE;
                                                padding: 2px 8px;
                                            }

                                            &.redColour {
                                                color: #CE2020;

                                                span {
                                                    background: #d1212117;
                                                }
                                            }

                                            &.blackColour {
                                                color: #000;
                                                border-radius: 0px;
                                                font-weight: 400;

                                                span {
                                                    background: #fff;
                                                    padding: 0;
                                                }
                                            }
                                        }

                                        &.bothSecAcc {
                                            &:nth-last-child(2) {
                                                color: #000;
                                                border-radius: 0px;
                                                font-weight: 400;

                                                span {
                                                    background: #fff;
                                                    padding: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    table {
                        width: 100%;
                        text-align: left;
                        border-collapse: collapse;

                        tr {
                            border-bottom: 0.5px solid #C8C8C8;

                            &:first-child {
                                border-bottom: 1px solid #000;
                                background: #F5F5F5;
                            }

                            th {
                                color: #000;
                                font-size: 12px;
                                font-weight: 600;
                                height: 40px;
                                padding: 0 10px;
                            }

                            td {
                                font-size: 14px;
                                height: 40px;
                                padding: 0 10px;

                                span {
                                    &.redValue {
                                        color: #CE2020;
                                    }
                                }

                                &:first-child {
                                    font-weight: 600;
                                }
                            }
                        }
                    }

                    @media (max-width: 767px) {
                        display: none;
                    }
                } 
            }

            @media (max-width: 1599px) {
                padding: 30px 20px 20px;
                height: 385px;
            }

            @media (max-width: 1199px) {
                padding: 25px;
                border-radius: 15px;
            }

            @media (max-width: 767px) {
                height: 340px;
            }
        }

        @media (max-width: 1199px) {
            width: 100%;
        }
    }

    @media (max-width: 1199px) {
        margin-top: 50px !important;
        display: block;
    }

    @media (max-width: 767px) {
        margin-top: 20px !important;
    }
}

.exportCSVButton {
    width: auto;
    display: inline-block;
    float: right;
    margin-right: 30px;
    margin-bottom: 10px;

    a {
        background: #4F8000;
        width: 100%;
        height: auto;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        text-decoration: none;
        display: flex;
        align-items: center;

        svg {
            margin-right: 10px;
        }
    }
}

.exportCSVUsers {
    width: auto;
    margin-right: 30px;

    a {
        background: #4F8000;
        width: 100%;
        height: auto;
        padding: 8px 10px;
        border-radius: 5px;
        color: #fff;
        text-decoration: none;
        display: flex;
        align-items: center;
        border-radius: 30px;

        svg {
            margin-right: 10px;
        }
    }
}

.btnNewUser {
    border-radius: 20px !important;
    margin-right: 15px !important;
}

.popupFilters {
    margin-bottom: 30px;

    .MuiSwitch-root {
        width: 57px;
        height: 34px;
    }

    .MuiSwitch-thumb {
        width: 15px;
        height: 15px;
        color: #757575;
    }

    .MuiSwitch-colorSecondary.Mui-checked {
        .MuiSwitch-thumb {
            color: #4F8000;
        }
    }

    .MuiSwitch-track {
        background-color: #b1b1b1;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: #b1b1b1;
    }

    .MuiTypography-body1 {
        font-size: 10px;

        @media (min-width: 1200px) {
            font-size: 14px;
        }
    }
}

.tooltipWrp {
    position: relative;

    &:hover {
        .tooltipBox {
            visibility: visible;
            display: block;
        }
    }

    .tooltipBox {
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.85);
        color: #fff;
        text-align: center;
        padding: 5px;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        font-size: 10px;
        font-weight: 500;
        box-shadow: #0000003d 0 0 4px 1px;
        min-width: 120px;
        top: 100%;
        display: none;
    }
}