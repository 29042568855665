.salesProfitHeader {
    display: flex !important;
    justify-content: space-around;
    width: 100% !important;
}

.br {
    border-right: 1px solid;
}

.mr {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.w-50 {
    width: 50% !important;
    padding: 7px 0px;
}
